import REVIEWS_COLLECTION from "./reviews.data";

const INITIAL_STATE = {
  reviewsCollection: REVIEWS_COLLECTION,
};

const reviewsReducer = (state = INITIAL_STATE, action) => {
  switch (action) {
    default:
      return state;
  }
};

export default reviewsReducer;
