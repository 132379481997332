import React from "react";

import { CartItemContainer, ItemDetailsContainer } from "./cart-item.styles";

const CartItem = ({ item }) => {
  const { name, imageUrl, price, quantity } = item;
  return (
    <CartItemContainer>
      <img src={imageUrl} alt="item" />
      <ItemDetailsContainer>
        <span className="name">
          {name} - {price.type}
        </span>
        <span className="price">
          {quantity} x {price.value}р
        </span>
      </ItemDetailsContainer>
    </CartItemContainer>
  );
};

export default CartItem;
