import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import CartIcon from "../cart-icon/cart-icon.component";
import CartDropdown from "../cart-dropdown/cart-dropdown.component";
import SideMenuToggleButton from "../side-menu-toggle-button/side-menu-toggle-button.component";
import SideMenu from "../sidemenu/side-menu.component";

import { selectCartHidden } from "../../redux/cart/cart.selectors";

import "./header.styles.scss";

class Header extends Component {
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 5) {
            document.querySelector(".header-container").className = "header-container scroll";
        } else {
            document.querySelector(".header-container").className = "header-container";

            if (this.props.location.pathname !== "/") {
                document.querySelector(".header-container").className = "header-container another-style";
            }
        }
    };

    render() {
        const { location, hidden } = this.props;
        return (
            <div className={`header-container ${location.pathname === "/" ? "" : "another-style"}`}>
                <div className="logo-container">
                    <Link to="/">Мёд из Абхазии</Link>
                </div>
                <div className="options-container">
                    <Link to="/" className="option-link">
                        ГЛАВНАЯ
                    </Link>
                    <Link
                        to="/about"
                        onClick={() => {
                            this.props.history.push("/about");
                        }}
                        className="option-link"
                    >
                        О МЁДЕ
                    </Link>
                    <Link to="/shop" onClick={() => this.props.history.push("/shop")} className="option-link">
                        КУПИТЬ МЁД
                    </Link>
                    <Link to="/reviews" onClick={() => this.props.history.push("/reviews")} className="option-link">
                        ОТЗЫВЫ
                    </Link>
                    <Link to="/contact" onClick={() => this.props.history.push("/contact")} className="option-link">
                        КОНТАКТЫ
                    </Link>
                    <SideMenuToggleButton />
                    <CartIcon />
                </div>
                {hidden ? null : <CartDropdown />}
                <div>
                    <SideMenu />
                </div>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    hidden: selectCartHidden,
});

export default withRouter(connect(mapStateToProps)(Header));
