import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { selectSideMenuHidden } from "../../redux/side-menu/side-menu.selectors.js";
import { toggleSideMenuHidden } from "../../redux/side-menu/side-menu.actions";

import "./side-menu.component.scss";

const SideMenu = ({ sideMenuHidden, history, toggleSideMenuHidden }) => {
  return (
    <div className={`${sideMenuHidden ? "" : "open"} side-menu`}>
      <div className="options-container-side-menu">
        <Link
          to="/"
          onClick={() => toggleSideMenuHidden()}
          className="option-link-side-menu"
        >
          ГЛАВНАЯ
        </Link>
        <Link
          to="/about"
          onClick={() => {
            history.push("/about");
            toggleSideMenuHidden();
          }}
          className="option-link-side-menu"
        >
          О МЁДЕ
        </Link>
        <Link
          to="/shop"
          onClick={() => {
            history.push("/shop");
            toggleSideMenuHidden();
          }}
          className="option-link-side-menu"
        >
          КУПИТЬ МЁД
        </Link>
        <Link
          to="/reviews"
          onClick={() => {
            history.push("/reviews");
            toggleSideMenuHidden();
          }}
          className="option-link-side-menu"
        >
          ОТЗЫВЫ
        </Link>
        <Link
          to="/contact"
          onClick={() => {
            history.push("/contact");
            toggleSideMenuHidden();
          }}
          className="option-link-side-menu"
        >
          КОНТАКТЫ
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  sideMenuHidden: selectSideMenuHidden,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSideMenuHidden: () => dispatch(toggleSideMenuHidden()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SideMenu)
);
