import React from "react";

import Slideshow from "../../components/slideshow/slideshow.component";
import About from "../../components/about/about.component";
import Directory from "../../components/directory/directory.component";
import Reviews from "../../components/reviews/reviews.component";

import "./home-page.styles.scss";

const HomePage = () => {
  return (
    <div>
      {/* <Slideshow /> */}
      <div className="homepage-container">
        <About
          textAbout={`Меня зовут Саша, но друзья шутливо называют «наш пасечник». И я начинаю
        откликаться. А всё потому, что моя любовь к Абхазии, в которую я езжу с
        1999 года, переросла три года назад в увлечение и пчеловодство. Мы с
        моим другом-пчеловодом поставили десяток ульев к северу от города Сухум
        в селе Гума. И начали кормить своих бабушек, дедушек, друзей, детей,
        детей друзей настоящим горным кавказским мёдом. Несколько раз в год мы
        привозим мёд в Москву, где продолжаем им всех кормить! Пишите, звоните,
        мы и вас накормим! Ваш пасечник.`}
          secondText={`Мёд - это забота о близких, тепло и уют. 
Все банки приятно и симпатично упакованы. Мёд здорово дарить коллегам (подходит для корпоративных подарков), друзьям, родителям и ходить с ним в гости - баночка хорошего мёда душевный, уютный и полезный подарок в каждом доме! 
Если вы живете далеко от семьи и родственников - это не значит, что близкие останутся без вашего внимания и вкусного подарка, доставка в любой город и страну.`}
        />
        <Directory />
        <Reviews />
      </div>
    </div>
  );
};

export default HomePage;
