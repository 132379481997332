import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import CheckoutItem from "../../components/checkout-item/checkout-item.component";

import { selectCartItems, selectCartTotal } from "../../redux/cart/cart.selectors";

import {
    CheckoutPageContainer,
    CheckoutHeaderContainer,
    HeaderBlockContainer,
    TotalContainer,
} from "./checkout.styles";

const cartItems = (items) => {
    let str = "";
    items.map((item) => {
        str += `${item.name} - ${item.price.type} x ${item.quantity} = ${item.price.value * item.quantity}р \n`;
    });
    return str;
};

class CheckoutPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            cartItem: this.props.cartItems,
            total: this.props.total,
            isVerified: false,
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            name: this.state.name,
            email: this.state.email,
            cartItems: cartItems(this.props.cartItems),
            total: `${this.props.total}р`,
            message: "Заказ с сайта med-abkhazii.ru",
        };

        emailjs.send("service_wad6vh4", "template_mww2jzg", data, "t-8dNg5bMvbs2GGXk").then(function (response) {
            console.log("SUCCESS!", response.status, response.text);
        });

        this.setState({
            name: "",
            email: "",
            isVerified: false,
        });

        alert("Спасибо за заказ. Ваш заказ принят, скоро мы свяжемся с вами");
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    onVerified = (res) => {
        if (res) {
            this.setState({
                isVerified: true,
            });
        }
    };

    render() {
        const { name, email, isVerified } = this.state;

        return (
            <CheckoutPageContainer>
                <CheckoutHeaderContainer>
                    <HeaderBlockContainer>
                        <span></span>
                    </HeaderBlockContainer>
                    <HeaderBlockContainer>
                        <span>Название</span>
                    </HeaderBlockContainer>
                    <HeaderBlockContainer>
                        <span>Кол-во</span>
                    </HeaderBlockContainer>
                    <HeaderBlockContainer>
                        <span>Цена</span>
                    </HeaderBlockContainer>
                    <HeaderBlockContainer>
                        <span>Удалить</span>
                    </HeaderBlockContainer>
                </CheckoutHeaderContainer>
                {this.props.cartItems.map((cartItem) => (
                    <CheckoutItem key={cartItem.price.idForCart} cartItem={cartItem} />
                ))}
                <TotalContainer>
                    Итого: {this.props.total}р
                    <div style={{ width: "250px" }}>
                        <form onSubmit={this.handleSubmit}>
                            <FormInput
                                type="text"
                                name="name"
                                value={name}
                                onChange={this.handleChange}
                                label="Имя"
                                required
                            />
                            <FormInput
                                type="email"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                                label="Ваш e-mail"
                                required
                            />
                            <ReCAPTCHA sitekey="6LdardEZAAAAAGkxSgxRDjzIlK1Ie_fmRkJMyHcv" onChange={this.onVerified} />
                            {isVerified ? (
                                <CustomButton
                                    style={{
                                        marginTop: "5px",
                                        width: "100%",
                                        backgroundColor: "#fa7921",
                                        color: "#fff",
                                        padding: "10px",
                                        fontSize: "1rem",
                                    }}
                                    type="submit"
                                >
                                    Отправить заказ
                                </CustomButton>
                            ) : null}
                        </form>
                    </div>
                </TotalContainer>
            </CheckoutPageContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    cartItems: selectCartItems,
    total: selectCartTotal,
});

export default connect(mapStateToProps)(CheckoutPage);
