import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectSideMenuHidden } from "../../redux/side-menu/side-menu.selectors";

import { toggleSideMenuHidden } from "../../redux/side-menu/side-menu.actions";

import "./side-menu-toggle-button.styles.scss";

const SideMenuToggleButton = ({ sideMenuHidden, toggleSideMenuHidden }) => {
  return (
    <div>
      {sideMenuHidden ? (
        <button
          className={`${
            toggleSideMenuHidden ? "active" : "non-active"
          } side-menu-button`}
          onClick={toggleSideMenuHidden}
        >
          <div className="side-button-line"></div>
          <div className="side-button-line"></div>
          <div className="side-button-line"></div>
        </button>
      ) : (
        <button
          className={`${
            toggleSideMenuHidden ? "active" : "non-active"
          } close-side-button`}
          onClick={toggleSideMenuHidden}
        >
          <div className="close-side-button-line"></div>
          <div className="close-side-button-line-2"></div>
        </button>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  sideMenuHidden: selectSideMenuHidden,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSideMenuHidden: () => dispatch(toggleSideMenuHidden()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideMenuToggleButton);
