import styled from "styled-components";

export const CheckoutItemContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;
  @media (max-width: 415px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  width: 23%;
  padding-right: 15px;
  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 415px) {
    width: 90%;
  }
`;

export const TextContainer = styled.span`
  width: 23%;
  @media (max-width: 415px) {
    width: 90%;
    text-align: center;
  }
`;

export const QuantityContainer = styled(TextContainer)`
  display: flex;
  span {
    margin: 0 10px;
  }
  div {
    cursor: pointer;
  }
  @media (max-width: 415px) {
    justify-content: center;
  }
`;

export const RemoveButtonContainer = styled.div`
  padding-left: 12px;
  cursor: pointer;
  @media (max-width: 415px) {
    padding-left: 0px;
  }
`;
