import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import cartReducer from "./cart/cart.reducer";
import shopReducer from "./shop/shop.reducer";
import reviewsReducer from "./reviews/reviews.reducer";
import sideMenuReducer from "./side-menu/side-menu.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cart"],
};

const rootReducer = combineReducers({
  cart: cartReducer,
  shop: shopReducer,
  reviews: reviewsReducer,
  sideMenu: sideMenuReducer,
});

export default persistReducer(persistConfig, rootReducer);
