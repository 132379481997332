import React from "react";
import { connect } from "react-redux";

import CustomButton from "../../components/custom-button/custom-button.component";

import { addItem } from "../../redux/cart/cart.actions";
import { selectTypeOfHoney } from "../../redux/shop/shop.selectors";

import "./item-page.styles.scss";
const ItemPage = ({ honeyType: { id, item }, match, addItem }) => {
  const { name, description, imageUrl } = item;
  let litersOfHoney = item.price.type;
  console.log("NAME:" + litersOfHoney);
  return (
    <div className="item-page-container">
      <div className="name-image-container">
        <h1>{name}</h1>
        <img src={imageUrl} alt="honey" />
      </div>
      <div className="description-container">
        <span className="description">{description}</span>
        <div className="price-button-container">
          <select
            name="price"
            onChange={(e) => {
              item.price = JSON.parse(e.target.value);
              litersOfHoney = item.price.type;
              console.log("NAME:" + litersOfHoney);
            }}
          >
            <option>Выберите объем</option>

            {item.price.price1 && (
              <option value={JSON.stringify(item.price.price1)}>
                &#127855; {item.price.price1.type} - {item.price.price1.value}р
              </option>
            )}
            {item.price.price2 && (
              <option value={JSON.stringify(item.price.price2)}>
                &#127855; 0.5л - {item.price.price2.value}р
              </option>
            )}
            {item.price.price3 && (
              <option value={JSON.stringify(item.price.price3)}>
                &#127855; 1л - {item.price.price3.value}р
              </option>
            )}
            {/* {item.price.price3 && (
              <option value={JSON.stringify(item.price.price3)}>
                🍯 1.5л - {item.price.price4.value}р
              </option>
            )} */}
          </select>
          <CustomButton isReviewsPage onClick={() => addItem(item)}>
            Добавить в корзину
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  honeyType: selectTypeOfHoney(ownProps.match.params.honeyId)(state),
});

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemPage);
