import styled, { css } from "styled-components";

const buttonStyles = css`
  &::before {
    background: #fa7921;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50.5%);
    z-index: -1;
    transition: all 0.6s ease;
    width: 0%;
    height: 100%;
  }

  &:hover {
    color: white;
    border: 1px solid black;
    z-index: 1;

    &::before {
      width: 100%;
    }
  }
`;

const reviewsButton = css`
  background-color: #fa7921;
  font-size: 1.5rem;
  color: #fff;
  margin: 10px 0;
  border: 1px solid transparent;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0);
  outline: 1px solid;
  outline-color: rgba(0, 0, 0, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5), 0 0 20px rgba(0, 0, 0, 0.2);
    outline-color: rgba(0, 0, 0, 0);
    outline-offset: 15px;
    text-shadow: 1px 1px 2px #427388;
  }
`;

const getButtonStyles = (props) => {
  return props.isReviewsPage ? reviewsButton : buttonStyles;
};

export const CustomButtonContainer = styled.button`
  border: 1px solid black;
  color: black;
  transition: ease 0.3s;
  position: relative;
  padding: 5px 5px;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  background-color: #e9e9ed;

  &:hover {
    cursor: pointer;
  }

  ${getButtonStyles}
`;
