import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectReviewsCollection } from "../../redux/reviews/reviews.selectors";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "./reviews-slider.styles.scss";

SwiperCore.use([Navigation]);

const ReviewsSlider = ({ reviews }) => {
  return (
    <div style={{ width: "90%", margin: "10px 10px" }}>
      <Swiper navigation loop autoplay spaceBetween={100} slidesPerView={1}>
        {reviews
          .filter((review, idx) => idx < 4)
          .map(({ name, pic, review, id }) => (
            <SwiperSlide key={id}>
              <div className="review-container">
                <span className="review">{review}</span>
                <img src={pic} alt="person" />
                <span className="person-name">{name}</span>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  reviews: selectReviewsCollection,
});

export default connect(mapStateToProps)(ReviewsSlider);
