import propolis from "../../assets/propolis.jpg";
import lipoviy from "../../assets/lipoviy.jpg";
import evkaliptoviy from "../../assets/evkaliptoviy.jpg";
import evkaliptoviySoti from "../../assets/evkaliptoviy-soti.jpg";
import lemon from "../../assets/lemon.jpg";
import kashtanoviy from "../../assets/kashtanoviy.jpg";
import akacia from "../../assets/akacia.jpg";
import medoviyNabor from "../../assets/sasha_honey2[447].jpg";

const SHOP_DATA = [
  {
    id: 1,
    routeName: "med-s-propolisom",
    item: {
      name: "Мёд с прополисом",
      description: `Мёд с прополисом - мёд взбитый с прополисом. Целебно будет при первых признаках простуды и боли в горле съесть ложечку мёда с прополисом.
      А ещё этот мёд и его терпкий, насыщенный вкус отлично сочетается с утренним кофе. 
      `,
      imageUrl: propolis,
      price: {
        price1: { value: 750, idForCart: 1, type: "0.25л" },
        price2: { value: 1450, idForCart: 2, type: "0.5л" },
        price3: { value: 2900, idForCart: 3, type: "1л" },
        // price4: { value: 2700, idForCart: 4, type: "1.5л" },
      },
    },
  },
  {
    id: 2,
    routeName: "lipovyi-med",
    item: {
      name: "Липовый мёд",
      description: `Липовый мёд - кажется, «любимец» всех детей. Это светлый сорт мёда, сладкий, если так можно охарактеризовать вкус мёда, подойдёт и к блинам, в творог, и в кашу. `,
      imageUrl: lipoviy,
      price: {
        price1: { value: 750, idForCart: 5, type: "0.25л" },
        price2: { value: 1450, idForCart: 6, type: "0.5л" },
        price3: { value: 2900, idForCart: 7, type: "1л" },
        // price4: { value: 2700, idForCart: 8, type: "1.5л" },
      },
    },
  },
  {
    id: 3,
    routeName: "evkaliptovyi-med",
    item: {
      name: "Эвкалиптовый мёд",
      description: `Эвкалиптовый мёд - темный сорт мёда, собирается пчёлами в период цветения эвкалиптов в Абхазии. Густой, ароматный, с насыщенным вкусом, тягучий мёд. `,
      imageUrl: evkaliptoviy,
      price: {
        price1: { value: 750, idForCart: 9, type: "0.25л" },
        price2: { value: 1450, idForCart: 10, type: "0.5л" },
        price3: { value: 2900, idForCart: 11, type: "1л" },
        // price4: { value: 2700, idForCart: 12, type: "1.5л" },
      },
    },
  },
  {
    id: 4,
    routeName: "citrusovyi-med",
    item: {
      name: "Цитрусовый мёд",
      description: `Цитрусовый мёд - это мёд периода цветения мандаринов, лимоном, апельсинов и грейпфрутов в Абхазии. Имеет в своём вкусе приятную кислинку. `,
      imageUrl: lemon,
      price: {
        price1: { value: 750, idForCart: 13, type: "0.25л" },
        price2: { value: 1450, idForCart: 14, type: "0.5л" },
        price3: { value: 2900, idForCart: 15, type: "1л" },
        // price4: { value: 2700, idForCart: 16, type: "1.5л" },
      },
    },
  },
  {
    id: 5,
    routeName: "kashtanovyi-med",
    item: {
      name: "Каштановый мёд",
      description: `Каштановый мёд - медовый король Абхазии, главный мёд этого региона. Это самый темный сорт мёда, горный, с нашей кочевой пасеки. Имеет вкус с горчинкой. `,
      imageUrl: kashtanoviy,
      price: {
        price1: { value: 750, idForCart: 17, type: "0.25л" },
        price2: { value: 1450, idForCart: 18, type: "0.5л" },
        price3: { value: 2900, idForCart: 19, type: "1л" },
        // price4: { value: 2700, idForCart: 20, type: "1.5л" },
      },
    },
  },
  {
    id: 6,
    routeName: "ned-beloy-akacii",
    item: {
      name: "Мёд Белой Акации",
      description: `Мёд Белой Акации  - в июне душистые грозди акации становятся источником такого же ароматного и невероятно вкусного мёда, который я вам предлагаю. Это светлый сорт мёда, почти прозрачный. По найденной информации в интернете именно этот сорт мёда имеет самый низкий гликемический индекс, что может быть важно для занимающихся фитнесом и больных диабетом.`,
      imageUrl: akacia,
      price: {
        price1: { value: 750, idForCart: 21, type: "0.25л" },
        price2: { value: 1450, idForCart: 22, type: "0.5л" },
        price3: { value: 2900, idForCart: 23, type: "1л" },
        // price4: { value: 2700, idForCart: 24, type: "1.5л" },
      },
    },
  },
  {
    id: 7,
    routeName: "evkaliptovyi-med-soti",
    item: {
      name: "Мёд белой акации в сотах",
      description: `Мёд в сотах хранится в самых что ни на есть естественных условиях, что позволяет ему сохранить максимум полезных веществ. Отрежьте кусочек мёда в сотах, положите в рот и медленно разжевывайте, наслаждайтесь вкусом, пока во рту не останется комочек воска.
      Сам воск тоже содержит множество полезных веществ. 
      `,
      imageUrl: evkaliptoviySoti,
      price: {
        price1: { value: 750, idForCart: 25, type: "300гр" },
      },
    },
  },
  {
    id: 8,
    routeName: "medoviy-nabor",
    item: {
      name: "Медовый набор (ассорти)",
      description: `Новогодний медовый набор - это вкусный, душевный и полезный подарок близким. Это возможность попробовать сразу все виды нашего мёда. Набор состоит из 6 разных баночек с мёдом небольшого объема по 0,25 л + рамка мёда в сотах 300 гр. 
      `,
      imageUrl: medoviyNabor,
      price: {
        price1: { value: 5250, idForCart: 26, type: "6 баночек + соты" },
      },
    },
  },
];

export default SHOP_DATA;
