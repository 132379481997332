import React from "react";
import { Route } from "react-router-dom";

import Directory from "../../components/directory/directory.component";
import ItemPage from "../item-page/item-page.component";

import "./shop-page.styles.scss";

const ShopPage = ({ match }) => {
  return (
    <div className="shop-page-container">
      <div className="shop-page-container-items">
        <Route exact path={`${match.path}`} component={Directory} />
        <Route path={`${match.path}/:honeyId`} component={ItemPage} />
      </div>
    </div>
  );
};

export default ShopPage;
