import React from "react";
import { withRouter } from "react-router-dom";

import CustomButton from "../custom-button/custom-button.component";

import "./shop-item.styles.scss";

const ShopItem = ({ item, routeName, history, match }) => {
  const { name, imageUrl } = item;

  return (
    <div className="items-container">
      <img src={imageUrl} alt="honey" />
      <div className="item-container">
        <span>{name}</span>
        <CustomButton
          onClick={() => history.push(`/shop/${routeName}`)}
          type="submit"
        >
          Купить!
        </CustomButton>
      </div>
    </div>
  );
};

export default withRouter(ShopItem);
