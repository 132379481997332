import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const selectShop = (state) => state.shop;

export const selectShopItems = createSelector(
  [selectShop],
  (shop) => shop.honeyCollections
);

export const selectTypeOfHoney = memoize((honeyCollectionUrlParam) =>
  createSelector([selectShopItems], (honeyCollections) =>
    honeyCollections.find(
      (honeyType) => honeyType.routeName === honeyCollectionUrlParam
    )
  )
);
