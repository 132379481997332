import person1 from "../../assets/persons/ekaterina-dobrynina.jpg";
import person2 from "../../assets/persons/olga-malceva.jpg";
import person3 from "../../assets/persons/tatyana-gajsenok.jpg";
import person4 from "../../assets/persons/mariya-artemova.jpg";
import person5 from "../../assets/persons/anya-myhamatulina-300x300.jpg";
import person6 from "../../assets/persons/Tatyana-N-va.jpg";
import person7 from "../../assets/persons/Viktoria-Sergeeva-300x297.jpg";
import person8 from "../../assets/persons/matiya-dardik.jpg";
import person9 from "../../assets/persons/soltomuradova.jpg";
import person10 from "../../assets/persons/tarasova.jpg";
import person11 from "../../assets/persons/velichinskaya.jpg";
import person12 from "../../assets/persons/shumihina.jpg";
import person13 from "../../assets/persons/perrote.jpg";
import person14 from "../../assets/persons/nikiforova.jpg";
import person15 from "../../assets/persons/micheev.jpg";
import person16 from "../../assets/persons/morozova.jpg";
import person17 from "../../assets/persons/mustyac.jpg";
import person18 from "../../assets/persons/luzgina.jpg";
import person19 from "../../assets/persons/kokoreva.jpg";
import person20 from "../../assets/persons/holodova.jpg";
import person21 from "../../assets/persons/dzkuya.jpg";
import person22 from "../../assets/persons/hlomova.jpg";
import person23 from "../../assets/persons/artemova.jpg";
import person24 from "../../assets/persons/chotii.jpg";
import person25 from "../../assets/persons/hlomova2.jpg";

const REVIEWS_COLLECTION = [
  {
    name: "Ольга Мальцева",
    review: `Товарищи, йоги! Ценители каштанового меда!  Мой поставщик ценного натурпродукта принял от пчел новую партию. Свежачокс! Так что, медовые мои, пишите Сашке. Настоящий душистый! ДУШЕВНЫЙ! Из добрых рук моей хорошей Александры! Весь мёд очень ароматный, душистый и как всегда настоящий и домашний. Становитесь медовыми! Люблю. Пишите Сашке.`,
    pic: person2,
    id: 2,
  },
  {
    name: "Ирина Величинская",
    review: `Замечательный мед. Взяла попробовать, а теперь остановиться не могу. И дочь на него подсадила. Она говорит, что влюблена в этот мед. За всю свою предыдущую жизнь столько мёда не ела, как за последний год. Спасибо Саша. Брали каштановый, эвкалиптовый, гречишный.`,
    pic: person11,
    id: 11,
  },
  {
    name: "Мария Артёмова",
    review: "Мы вчера по дороге домой треть банки сожрали. На двоих.",
    pic: person4,
    id: 4,
  },
  {
    name: "Аня Мухаматулина",
    review: `Я очень люблю мед. Я его добавляю в кашу, в соусы, в чай, ем просто так. Сегодня я забрала мед у Александры, которая открыла свой магазин http://med-abkhazii.ru/
        Это чума! Я всем рекомендую! Просто невероятно вкусно. Сижу и чувствую себя Винни Пухом, попавшим в Рай.`,
    pic: person5,
    id: 5,
  },

  {
    name: "Viktoria Sergeeva",
    review: `У нашей прекрасной медовой феи Александры появилась партия каштанового меда нового урожая, которым он с вами с удовольствием поделится за умеренную плату. Этот мед для меня как тяжелый наркотик, «сижу» на нем уже пару лет))) Попробовав его хоть раз уже невозможно забыть этот вкус и променять на другой мед. Кроме, того он очень полезный и….красивый. Как будто солнечную негу разлили по бутылкам. Ну, и излишне говорить, что мед с домашней пасеки совершенно натрульный.

        Одно из главных лакомств последних лет, каштановый мед, потом к нему присоеденился эвкалиптовый. Райская нега, не есть сложно, но и есть сложно, потому что хочется продлить удовольствие. Любовь навсегда. Пока писала комментарий, поняла, что нужно срочно пойти съесть пару ложек.`,
    pic: person7,
    id: 7,
  },
  {
    name: "Татьяна Гайсенок",
    review: `Первый раз Сашин мед мы попробовали 2 (или уже 3???) года назад. И сразу поняли, что никакой другой теперь покупать не захотим и есть не сможем, потому, что разница между просто хорошим медом и этим-колоссальная. Мед душистый, терпкий, насыщенный, каштановый совсем, а акация очень долго не засахаривается, а мы все любим именно жидкий мед. Дети теперь просят блины, сырники, запеканки полить медом, но только «тетяСашиным». Подсадили на этот мед всех родных, заказываем теперь мелким оптом, чтобы на всех хватило. Спасибо, Саша-пчелка!

        Покупаем уже третий или четвёртый год. Восхитительный мёд! Подсадили на него всех родных и знакомых. Наши фавориты: каштан- терпкий, горьковатый и акация-нежная, душистая, очень нравится детям, практически не засахаривается. С нетерпением ждём нового урожая!`,
    pic: person3,
    id: 3,
  },
  {
    name: "Мария Дардык",
    review:
      "У Александры приехал замечательный каштановый мед из Абхазии. Всем, кто любит, очень советую. Пасека своя, вкус отменный, мною лично и моим семейством протестированный.",
    pic: person8,
    id: 8,
  },
  {
    name: "Екатерина Добрынина",
    review: `Не могу молчать! У Саши опять есть абхазский мед, новый урожай. Вчера в очередной раз к ней заехала, теперь у меня есть банка гречишного и банка акациевого+цитрус. Представляю, как это все цвело и как жужжали пчелы :)) Да, это реклама и информация! А то потом мои гости этот мед пробуют и ко мне с претензией — «а сказать не могла, мы бы тоже купили, да?» Говорю вот «городу и миру». Этот мед душистый, даже если его бухнуть в чай с молоком. И тем более если залить им клюкву, намазать на тост или — «положи-ка мне ложечку на мороженое… ээээ, большую, не жадничай!»
        Супер мед. Саша, спасибо!
        
        Вкусный и ароматный мед, пахнущий летом и солнцем. Один недостаток — быстро кончается, сколько ни возьми.`,
    pic: person1,
    id: 1,
  },
  {
    name: "Наталья Солтомурадова",
    review: `Мы не любители мёда, но за неделю съели банку липового. Акцию не пробовали. Средний потом ещё без нас съел банку каштанового и эвкалиптового. Но я, все таки, предпочитаю липовый. Он невероятно ароматный.`,
    pic: person9,
    id: 9,
  },
  {
    name: "Vasilisa Tarasova",
    review: `Мёд замечательный! Эвкалиптовый — нежный, гладкой текстуры, с легкой кислинкой и тонким ароматом. Вкус этого меда ассоциируется с изящной дамой. Гречишный — плотный, не тягучий, слегка горьковатый. Вкус этого меда — брутальный мачо! Каштановый подарила друзьям на Новый год. Они в восторге. К сожалению, другие сорта меда не попробовали. Ждем новый урожай.`,
    pic: person10,
    id: 10,
  },

  {
    name: "Tatyana N-va",
    review: `Я большой любитель меда, а особенно пользуется успехом у нас в семье имбирно-медовое печенье. Всю зиму мы печем его по несколько раз в неделю, и все мало. Но когда впервые два года назад я купила у Саши мед, я поняла, что до этого я ничего не понимала в меде:) Более душистого, богатого на вкус и нежного меда я никогда не ела. Я не знаю, какой из них вкусней — липовый? Каштановый? Все! Купила добавку, себе, в подарок свекру, тетушкам, принесла на работу угостить-и все коллеги тоже попросили домой себе такой мед. И наше фирменное печенье с этим медом-самое ароматное и вкусное. Спасибо лету, солнцу и Саше!

        У него очень насыщенный вкус, такой, что после него любой другой кажется бледной тенью. Ароматный, душистый. Этот мед очень вкусно и просто есть, а какие с ним получаются торты и печенье!`,
    pic: person6,
    id: 6,
  },
  {
    name: "Шумихина Мария",
    review: `Я уже запуталась, какой мед — какой, но они все были потрясающий, и каштан, и акация, и какой-то еще! При том, что мы мед в обычной жизни почти не едим, но тут такой аромат, аааа!!`,
    pic: person12,
    id: 12,
  },
  {
    name: "Мария Перроте",
    review: `Наивкуснейший, красивый мёд! Очень понравился и большим, и маленьким, а еще соты — это так вкусно!`,
    pic: person13,
    id: 13,
  },
  {
    name: "Наталия Никифорова",
    review: `Я покупала эвкалиптовый. Рекомендую всем. Очень душистый и насыщенный, оторваться трудно.`,
    pic: person14,
    id: 14,
  },
  {
    name: "Сергей Михеев",
    review: `Божественно ароматный! Похоже на райский Нектар!`,
    pic: person15,
    id: 15,
  },
  {
    name: "Ника Морозова",
    review: `Мы едим, нам нравится. Я покупаю только у тебя.`,
    pic: person16,
    id: 16,
  },
  {
    name: "Ольга Мустяц",
    review: `Отличный эвкалиптовый, раньше даже не знала, что из эвкалипта делают мед. По ощущениям он что ли чуть менее сладкий, чем другие виды, что особенно понравилось, я так-то сладкого не любитель. Остальной пока не пробовала, но собираюсь как-нибудь взять, как минимум в подарок тем, кто сладкого любитель. А, и отдельно понравилась упаковка, на банках были здорово оформлены крышечки, причем на обеих по-разному (не знаю, Саша, это у вас новогодняя акция была с красивыми крышечками или всегда так?
            Ответ Александра Маштакова:
            Баночки вседа симпатично оформлены, в любое время года подходят для приятных подарков родственникам, друзьям и коллегам. `,
    pic: person17,
    id: 17,
  },
  {
    name: "Светлана Лузгина",
    review: `Понравилось то, что вкусный. И ещё я тебе верю, что он настоящий, без фокусов и воды.`,
    pic: person18,
    id: 18,
  },
  {
    name: "Александра Кокорева",
    review: `Прекрасный мёд. Эвкалиптовый и каштановый с горчинкой, что придаёт пикантности. Вся семья ест с удовольствием. И многие друзья.`,
    pic: person19,
    id: 19,
  },
  {
    name: "Евгения Холодова",
    review: `Очень красивый! Тёмно-янтарный! Каштановый. С горчинкой необыкновенной, тянучий, прозрачный, с ложки длиннющей тонкой тягучей струёй стекает — красота! Просто банка с мёдом на столе — уже украшение, как будто светится. И насыщенный, плотный, роскошный совершенно вкус! Саша, спасибо огромное!
        Сын, правда, кроме того что ест его с удовольствием, время от времени клянчит хоть ложечку в мыльную основу добавлять, когда мыло заливает (мыловар доморощеный). Я ворчу, ругаюсь (жалко!), но разрешаю немного совсем, потому что он говорит, что ему только самое настоящее подойдёт.
        Надо Вам при случае его медовое мыло с Вашим мёдом в составе передать как-нибудь.`,
    pic: person20,
    id: 20,
  },
  {
    name: "Анастасия Дзкуя",
    review: `Мёд замечательный! Лучшее спасение от простуд и осенне-зимних печалей. Медовые наборы отличная идея!`,
    pic: person21,
    id: 21,
  },
  {
    name: "Елизавета Хломова",
    review: `Да, мёд, что надо! Каштановый особенно очень крутой!`,
    pic: person22,
    id: 22,
  },
  {
    name: "Мария Артёмова",
    review: `Акациевый. Моя любовь. Вкусный. Тягучий. ароматный. Умеренно сладкий. С частичкой солнца внутри. Жалко, заканчивается быстро…`,
    pic: person23,
    id: 23,
  },
  {
    name: "Катерина Чотий",
    review: `Покупали каштановый и эвкалиптовый мед, едим его сами и дарим ваши красивые баночки друзьям и родным на праздники. Мед с таким ярким вкусом и ароматом,какой был только в детстве!`,
    pic: person24,
    id: 24,
  },
  {
    name: "Софья Хломова",
    review: `Совершенно волшебный мёд! Сперва он очень красивый, потом очень вкусный, потом он закончился и хочется ещё.`,
    pic: person25,
    id: 25,
  },
];

export default REVIEWS_COLLECTION;
