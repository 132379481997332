import React from "react";

import "./about.styles.scss";

import imgSasha from "../../assets/sasha1.png";

const About = ({ textAbout, secondText }) => {
  return (
    <div className="about-container about-page">
      <h3>Мёд из Абхазии</h3>
      <img src={imgSasha} alt={`person`} />
      <p>{textAbout}</p>
      <br />
      <p>{secondText}</p>
    </div>
  );
};

export default About;
