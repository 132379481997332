import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectReviewsCollection } from "../../redux/reviews/reviews.selectors";

import "./reviewspage.styles.scss";

const ReviewsPage = ({ reviews }) => {
  return (
    <div className="reviews-page-container">
      <div className="review-container">
        {reviews.map(({ name, review, pic, id }) => (
          <div id={id} className="review">
            <span className="name-person">{name}</span>
            <img src={pic} alt="person" />
            <span className="review-text">{review}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  reviews: selectReviewsCollection,
});

export default connect(mapStateToProps)(ReviewsPage);
