import React from "react";
import { Slide } from "react-slideshow-image";

import { slideImages } from "./slideImages";

import "./slideshow.styles.scss";
import "react-slideshow-image/dist/styles.css";

const Slideshow = () => {
  return (
    <div styles={{ position: "absolute" }} className="slide-container">
      <Slide easing="ease" duration={5000}>
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${slideImages[0]})` }}>
            <span className="large-text">Натуральный мёд из Абхазии</span>
            <span>
              Попробуйте натуральный мёд, собранный пчёлами на чистых горных
              лугах. Мёд содержит большое количество полезных веществ.
            </span>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${slideImages[1]})` }}>
            <span className="large-text">Пища Богов</span>
            <span>
              «Мёд, принимаемый с другой пищей, питателен и дает хороший цвет
              лица». Гиппократ
            </span>
          </div>
        </div>
        <div className="each-slide">
          <div style={{ backgroundImage: `url(${slideImages[2]})` }}>
            <span className="large-text">Вкусный и полезный подарок</span>
            <span>
              Мёд - это оригинальная и полезная альтернатива традиционным
              подарочным конфетам.
            </span>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default Slideshow;
