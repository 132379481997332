import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Header from "./components/header/header.component";
import HomePage from "./pages/homepage/home-page.component";
import AboutPage from "./pages/aboutpage/aboutpage.component";
import ShopPage from "./pages/shoppage/shop-page.component";
import ReviewsPage from "./pages/reviewspage/reviewspage.component";
import CheckoutPage from "./pages/checkoutpage/checkout.component";
import ContactPage from "./pages/contactpage/contactpage.component";
import Footer from "./components/footer/footer.component";

import "./App.scss";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/about" component={AboutPage} />
          <Route path="/shop" component={ShopPage} />
          <Route exact path="/checkout" component={CheckoutPage} />
          <Route exact path="/reviews" component={ReviewsPage} />
          <Route exact path="/contact" component={ContactPage} />
        </Switch>

        <Footer />
      </div>
    );
  }
}

export default App;
