import React from "react";

import "./footer.styles.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-items-container">
        <div className="footer-item">
          <span className="footer-name">Контакты</span>

          <span className="underline"></span>
          <span className="footer-text">
            Email: med.abkhazii@gmail.com
            <br />
            <br />
            +7917-533-33-09 Messenger, WhatsApp
          </span>
        </div>
        <div className="footer-item">
          <span className="footer-name">Адрес</span>
          <span className="underline"></span>
          <span className="footer-text" style={{ fontSize: "1rem" }}>
            Доставка по Москве курьером (350р) <br /> <br />
            Доставка в регионы и по всему миру по тарифам Почты России
          </span>
        </div>
        <div className="footer-item">
          <span className="footer-name">Социальные сети</span>
          <span className="underline"></span>
          <span className="">
            <a href="https://www.facebook.com/profile.php?id=100001574422799">
              <i className="fa fa-facebook-square  icon" aria-hidden="true"></i>
            </a>
            <a href="https://instagram.com/zanozinmed?igshid=f7qymvg64zw6">
              <i className="fa fa-instagram icon" aria-hidden="true"></i>
            </a>
          </span>
        </div>
      </div>
      <div className="bottom-footer-container">
        <div className="bottom-footer">
          <div className="bottom-footer-item">
            <span>
              Copyright &copy; 2014 - {new Date().getFullYear()} Мёд из Абхазии.
              <br />
              All rights reserved.
            </span>
          </div>
          <div className="bottom-footer-item">
            <span>
              Created by Dmitrii Fomin
              <br />
              <a href="https://github.com/Kanadets">
                <i className="fa fa-github icon" aria-hidden="true"></i>
              </a>
              <a href="https://www.linkedin.com/in/dmitrii-fomin-383410142/">
                <i
                  className="fa fa-linkedin-square icon"
                  aria-hidden="true"
                ></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
