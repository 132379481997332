import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import ShopItem from "../shop-item/shop-item.component";

import { selectShopItems } from "../../redux/shop/shop.selectors";

import "./directory.styles.scss";

const Directory = ({ shopItems }) => {
  return (
    <div className="directory-container">
      {shopItems.map(({ id, ...otherShopItems }) => (
        <ShopItem key={id} {...otherShopItems} />
      ))}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  shopItems: selectShopItems,
});

export default connect(mapStateToProps)(Directory);
