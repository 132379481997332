import React from "react";
import { withRouter } from "react-router-dom";

import ReviewsSlider from "./reviews-slider.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import "./reviews.styles.scss";

const Reviews = ({ history }) => {
  return (
    <div className="reviews-container">
      <h3>Отзывы</h3>
      <div className="icon-container">
        <span className="outer-line"></span>
        <span className="icon">
          <i className="fa fa-pagelines"></i>
        </span>
        <span className="outer-line"></span>
      </div>
      <ReviewsSlider />
      <CustomButton
        className="reviews-page-button"
        onClick={() => history.push("/reviews")}
      >
        Посмотреть все отзывы
      </CustomButton>
    </div>
  );
};

export default withRouter(Reviews);
