import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import "./contactpage.styles.scss";

class ContactPage extends Component {
    constructor() {
        super();

        this.state = {
            name: "",
            email: "",
            topic: "Вопрос",
            message: "",
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            name: this.state.name,
            email: this.state.email,
            topic: this.state.topic,
            message: this.state.message,
        };

        emailjs.send("service_wad6vh4", "template_lxfnbun", data, "t-8dNg5bMvbs2GGXk");

        this.setState({
            name: "",
            email: "",
            topic: "Вопрос",
            message: "",
            isVerified: false,
        });

        alert("Спасибо что связались с нами! Мы свяжемся с вами в ближайшее время");
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    onVerified = (res) => {
        if (res) {
            this.setState({
                isVerified: true,
            });
        }
    };

    render() {
        const { name, email, topic, message, isVerified } = this.state;

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div className="contact-page-container">
                    <h2 style={{ textAlign: "left" }}>Напишите нам!</h2>
                    <form className="contact" onSubmit={this.handleSubmit}>
                        <FormInput
                            type="text"
                            name="name"
                            value={name}
                            onChange={this.handleChange}
                            label="Имя"
                            required
                        />
                        <FormInput
                            type="email"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                            label="Ваш e-mail"
                            required
                        />
                        <select name="topic" value={topic} onChange={this.handleChange}>
                            <option value="Вопрос">Вопрос</option>
                            <option value="Отзыв">Отзыв</option>
                        </select>
                        <textarea
                            rows="4"
                            cols="50"
                            name="message"
                            form="usrform"
                            required
                            placeholder="Введите ваше сообщение"
                            value={message}
                            onChange={this.handleChange}
                        ></textarea>
                        <ReCAPTCHA sitekey="6LdardEZAAAAAGkxSgxRDjzIlK1Ie_fmRkJMyHcv" onChange={this.onVerified} />
                        {isVerified ? <CustomButton type="submit">Отправить</CustomButton> : null}
                    </form>
                </div>
            </div>
        );
    }
}

export default ContactPage;
